<template>
  <div>
    <vue-headful
      title="SJR Ulm - Wer wir sind"
      description="Erfahrt hier mehr über die Aufgaben und Ziele, das Team und den Leitsatz des Stadtjugendrings Ulm e.V."
    />
    <TitleToolbar
      :title="title"
    />
    <Header
      headline="DER STADT<wbr>JUGEND<wbr>RING ULM E.V."
      headlineSubtitle="Der Stadtjugendring Ulm e.V. ist der Dachverband von Jugendverbänden, Jugendgruppen und Jugendinitiativen in Ulm. Er vertritt die Interessen und Bedarfe von Kindern und Jugendlichen in der Stadt. Im Stadtjugendring soll sich die Vielfalt und Breite einer bunt gemischten Jugend und Gesellschaft mit unterschiedlichen, sich gegenseitig akzeptierenden und respektierende Vorstellungen widerspiegeln."
      imageSource="/img/wer-wir-sind.jpg"
      backgroundColor="#009A99"
    />
    <TitleToolbar
      color="white"
      title="UNSERE AUFGABEN & ZIELE"
    />
    <v-container
      class="px-8 mb-6"
      fluid
    >
      <v-row>
        <v-col
          v-for="(goal, i) in goals"
          :key="i"
          cols="12"
          sm="6"
          md="3"
          class="pb-6"
        >
          <v-row
            no-gutters
            class="justify-center pb-6"
          >
            <v-img :src="'/img/goal-' + (i+1) + '.svg'" max-width="60%" />
          </v-row>
          <v-row
            no-gutters
            class="justify-center"
          >
            <p
              class="goal-item-text primary--text"
              style="max-width: 75%;"
            >
                {{goal}}
            </p>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <TitleToolbar
        title="DER STADTJUGENDRING ULM E.V. IST"
    />
    <v-container
      class="px-8 mb-6 lightAccent"
      fluid
    >
      <v-row>
        <v-col
          cols="12"
          sm="4"
          v-for="(verification, i) in verifications"
          :key="i"
        >
          <v-row>
            <v-col
              cols="12"
              md="12"
              class="pb-6"
            >
              <v-row
                :class="textJustify"
              >
                <v-col class="pa-0" align="center">
                  <v-img src="/img/check-mark-1.svg" :max-width="$vuetify.breakpoint.smAndUp ? 70 : 50" />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              no-gutters
              cols="12"
              md="12"
            >
              <v-row
                no-gutters
                :class="textJustify"
              >
                <v-col
                  align="center"
                  class="recognised-provider-item-text primary--text"
                >
                  {{verification}}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <TitleToolbar
      title="UNSER TEAM"
      color="white"
    />
    <v-container
      class="px-8 mb-6"
      fluid
    >
      <v-row>
        <PersonCol
          v-for="(teamMember, i) in team"
          :key="i"
          :fullName="teamMember.fullName"
          :specialPosition="teamMember.specialPosition"
          :position="teamMember.position"
          :imageSource="teamMember.imageSource"
          :phone="teamMember.phone"
          :mailAddress="teamMember.mailAddress"
        />
      </v-row>
    </v-container>
    <TitleToolbar
      title="VORSTAND"
      color="white"
      subTitle="Der Stadtjugendring Ulm e.V. hat in seiner Mitgliederversammlung am 14. November 2023 seinen Vorstand gewählt. Er besteht aus:"
    />
    <v-container
      class="px-8 mb-6"
      fluid
    >
      <v-row>
        <PersonCol
          v-for="(boardMember, i) in board"
          :key="i"
          :fullName="boardMember.fullName"
          :position="boardMember.position"
          :imageSource="boardMember.imageSource"
          :bodyPrimary="boardMember.bodyPrimary"
          :bodySecondary="boardMember.bodySecondary"
          :mailAddress="boardMember.mailAddress"
        />
      </v-row>
    </v-container>
    <TitleToolbar
      title="HAUPTAUSSCHUSS"
    />
    <v-container
      class="px-8 mb-6 lightAccent"
      fluid
    >
      <v-row
        v-for="(committeeMember, i) in committee"
        :key="i"
      >
        <v-col
            cols="12"
            sm="6"
            class="main-committee-primary primary--text"
            :class="$vuetify.breakpoint.smAndUp ? '' : 'pb-0'"
        >
            {{committeeMember.name}}
        </v-col>
        <v-col
            cols="12"
            sm="6"
            class="main-committee-secondary primary--text"
            :class="$vuetify.breakpoint.smAndUp ? '' : 'pt-0'"
        >
            {{committeeMember.organisation}}
        </v-col>
      </v-row>
    </v-container>
    <TitleToolbar
      title="LEITBILD"
      color="white"
    />
    <v-container
        class="px-8 mb-6"
        fluid
    >
      <v-row>
        <v-col
          class="statutes statutes-title primary--text"
        >
          Grundsatz
        </v-col>
      </v-row>
      <v-row
        class="pb-6"
      >
        <v-col
          cols="12"
        >
          <span
            class="statutes primary--text"
          >
            Der Stadtjugendring Ulm e.V. ist der Dachverband von Jugendverbänden, Jugendgruppen und Jugendinitiativen in Ulm. Er vertritt die Interessen und Bedarfe von Kindern und Jugendlichen in der Stadt. Im Stadtjugendring soll sich die Vielfalt und Breite einer bunt gemischten Jugend und Gesellschaft mit unterschiedlichen, sich gegenseitig akzeptierenden und respektierenden Vorstellungen widerspiegeln. Grundlage hierfür ist die Anerkennung der jeweiligen Werte der einzelnen Jugendverbände, die sich im Rahmen und Schutz unseres Grundgesetzes selbst und individuell verwirklichen können.
          </span>
        </v-col>
      </v-row>
        <template
          v-if="showMissionStatement"
        >
          <v-row
            class="pb-6"
          >
            <v-col
                class="statutes statutes-title primary--text"
            >
                Ziele und Grundlagen unserer Arbeit
            </v-col>
          </v-row>
          <v-row
              class="pb-6"
          >
            <v-col
              cols="12"
            >
              <p
                class="statutes primary--text"
              >
                Der Stadtjugendring ist ein Organismus, der sich durch die Partizipation und Vielfalt seiner Mitglieder und deren ehrenamtliches Engagement stetig selbst belebt und erneuert.
              </p>
              <p
                class="statutes primary--text"
              >
                Unser Ziel ist es, der Jugend, der Jugendarbeit und der Jugendkultur im öffentlichen Raum zur angemessenen Geltung zu verhelfen. Dadurch leisten wir gemeinsam einen positiven Beitrag zum Angebot und Ruf der Stadt Ulm.
              </p>
              <p
                class="statutes primary--text"
              >
                Zu den wichtigsten Wesensmerkmalen unserer Arbeit zählen Förderung und Erhalt von Selbstbestimmung, Selbstorganisation, Gemeinschaftssinn, Demokratie, Mitbestimmung und sozialem Engagement von Kindern und Jugendlichen.
              </p>
              <p
                class="statutes primary--text"
              >
                Der Stadtjugendring fördert die Vielfalt der Stadtgesellschaft und bezieht, soweit möglich, alle Menschen, unabhängig von Geschlecht, Alter, körperlicher oder geistiger Verfassung, sexueller Orientierung, sozialer, kultureller und religiöser Herkunft mit in seine Angebote und Leistungen ein. Wir stellen uns dabei gegen faschistische und nationalistische Tendenzen, Ausgrenzung, Intoleranz, Sexismus, Demokratiefeindlichkeit und treten für eine pluralistische, vielfältige, freie und gleichwürdige Gesellschaft unter Wahrung der Menschenwürde ein.
              </p>
              <p
                class="statutes primary--text"
              >
                Wir verstehen uns als Förderer und Stifter von Innovationen und als Plattform neuer, zeitgemäßer Ideen und Bedarfe von Kindern und Jugendlichen.
              </p>
              <p
                  class="statutes primary--text"
              >
                Der Stadtjugendring nimmt die Interessenvertretung seiner Mitglieder gegenüber politischen Institutionen und städtischen Gremien wahr. Als wichtigste Partner dabei verstehen wir den Ulmer Gemeinderat und die Verwaltung der Stadt Ulm, sowie deren Mitarbeitende.
              </p>
            </v-col>
          </v-row>
          <v-row
            class="ma-0 px-0 pt-0 pb-6"
          >
            <span
              class="statutes statutes-title primary--text"
            >
              Weiter verfolgen wir die Ziele:
            </span>
          </v-row>
          <v-row
            class="ma-0 px-0 pt-0 pb-6"
          >
            <v-col
                cols="12"
                md="8"
                class="ma-0 pa-0"
            >
              <span
                  class="statutes primary--text"
              >
                <ul>
                  <li>
                    Auflösung und Prävention von Vorurteilen und Barrieren
                  </li>
                  <li>
                    Ermöglichung von Bildung, Teilhabe und Beteiligung
                  </li>
                  <li>
                    Frieden, Toleranz und ein soziales Füreinander
                  </li>
                  <li>
                    Geschlechtergerechte Sprache
                  </li>
                  <li>
                    Kindeswohl und soziale Gerechtigkeit
                  </li>
                  <li>
                    Medienfreiheit und freie Meinungsäußerung
                  </li>
                  <li>
                    Nachhaltigkeit und Umweltbewusstsein
                  </li>
                  <li>
                    Schaffung zeitgemäßer Maßnahmen und Strukturen
                  </li>
                  <li>
                    vertrauensvoller und sensibler Umgang mit personenbezogenen Daten und Rechten
                  </li>
                </ul>
            </span>
          </v-col>
        </v-row>
      </template>
      <v-row justify="center" class="py-6">
          <v-btn
              @click="showMissionStatement = !showMissionStatement"
              width="190px"
              elevation=0
              tile
              color="secondary"
          >
            {{showMissionStatement ? 'Weniger anzeigen' : 'Mehr anzeigen'}}
          </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import Header from '@/components/Header'
import TitleToolbar from '@/components/TitleToolbar'
import PersonCol from '@/components/PersonCol'

export default {
  name: 'WhoWeAre',
  components: {
    Header,
    TitleToolbar,
    PersonCol
  },
  data: () => ({
    title: 'WER WIR SIND',
    showMissionStatement: false,
    team: [
      /*
      {
        fullName: 'TOBIAS „BOBBES“ SCHMID',
        position: 'Geschäftsführung',
        imageSource: '/img/person/Tobias_Schmid.jpg',
        phone: '0731–140 69 15',
        mailAddress: 'schmid@sjr-ulm.de'
      },
      */
      {
        fullName: 'ACHIM SPANNAGEL',
        position: 'Geschäftsführung',
        imageSource: '/img/person/Achim_Spannagel.jpg',
        phone: '0731–140 69 17',
        mailAddress: 'spannagel@sjr-ulm.de'
      },
      {
        fullName: 'JULIANE KAISER',
        position: ' Mitgliederservice, Assistenz der Geschäftsführung',
        imageSource: '/img/person/Juliane.jpg',
        phone: '0731–140 69 11',
        mailAddress: 'kaiser@sjr-ulm.de'
      },
      {
        fullName: 'JANA SEIFERT',
        position: ' Mitgliederservice, feuerrot&neonblau',
        imageSource: '/img/person/Jana.jpg',
        phone: '0731–140 69 13',
        mailAddress: 'seifert@sjr-ulm.de'
      },
      {
        fullName: 'SABINE SCHRÖPPEL',
        position: 'Buchhaltung, Mitgliederservice',
        imageSource: '/img/person/Sabine_Schroeppel.jpg',
        phone: '0731–140 69 12',
        mailAddress: 'schroeppel@sjr-ulm.de'
      },
      {
        fullName: 'KATHRIN KÖHN',
        position: 'Ulmer Unke',
        imageSource: '/img/person/Kathrin.jpg',
        phone: '0731-140 69 19',
        mailAddress: 'koehn@sjr-ulm.de'
      },
      {
        fullName: 'ALINA NÖTZEL',
        position: 'Studentin DHBW',
        imageSource: '/img/person/Alina.jpg',
        phone: '0731-140 69 10',
        mailAddress: 'noetzel@sjr-ulm.de'
      },
      {
        fullName: 'LAURIN BASLER',
        position: 'popbastion',
        imageSource: '/img/person/Laurin_Basler.jpg',
        phone: '0731-140 69 20',
        mailAddress: 'basler@sjr-ulm.de'
      },
      {
        fullName: 'TIMO FREUDENREICH',
        position: 'e.tage medien bildung',
        imageSource: '/img/person/Timo_Freudenreich.jpg',
        phone: '0731–140 69 24',
        mailAddress: 'freudenreich@sjr-ulm.de'
      },
      {
        fullName: 'ALEXANDRA JUNG',
        position: 'e.tage medien bildung',
        imageSource: '/img/person/Alex.jpg',
        phone: '0731–140 69 14',
        mailAddress: 'jung@sjr-ulm.de'
      },
      {
        fullName: 'STEPHAN KOLB',
        position: 'Jugendbeteiligung, Jugend aktiv in ulm, hin & weg',
        imageSource: '/img/person/Stephan_Kolb.jpg',
        phone: '0731–140 69 25',
        mailAddress: 'kolb@sjr-ulm.de'
      },
      {
        fullName: 'JÜRGEN RITTMANN',
        position: 'Räume',
        imageSource: '/img/person/Juergen_2.jpg',
        phone: '0731–140 69 16',
        mailAddress: 'rittmann@sjr-ulm.de'
      },
      {
        fullName: 'ARIANE SCHÖNHEIT',
        position: 'Studentin DHBW',
        imageSource: '/img/person/Ariane_Schoenheit.jpg',
        phone: '0731–140 69 23',
        mailAddress: 'schoenheit@sjr-ulm.de'
      },
      {
        fullName: 'HANNAH MEDERER',
        position: 'FSJ Kultur',
        imageSource: '/img/person/Hannah_Mederer.jpg',
        phone: '0731–140 69 14',
        mailAddress: 'mederer@sjr-ulm.de'
      },
      {
        fullName: 'MUBINA JASAREVIC',
        position: 'Azubi',
        imageSource: '/img/person/Mubina_Jasarevic.jpg',
        phone: '0731–140 69 16',
        mailAddress: 'jasarevic@sjr-ulm.de'
      },
      {
        fullName: 'ZÜLEYHA KANDEMIR',
        position: 'Azubi',
        imageSource: '/img/person/Zueleyha.jpg',
        phone: '0731–140 69 16',
        mailAddress: 'kandemir@sjr-ulm.de'
      }
    ],
    board: [
      {
        fullName: 'GEORG BAIER',
        position: 'Vorsitzender',
        imageSource: '/img/person/Georg.jpg',
        bodyPrimary: 'Seit 2023 im Vorstand des Stadtjugendring Ulm e.V.',
        bodySecondary: 'Mitglied im DPSG Söflingen'
      },
      {
        fullName: 'KATHRIN PRZEWODNIK',
        position: 'Vorstandsmitglied',
        imageSource: '/img/person/Kathrin_Przewodnik.jpg',
        bodyPrimary: 'Seit 2023 im Vorstand des Stadtjugendring Ulm e.V.',
        bodySecondary: 'Mitglied im UstA'
      },
      {
        fullName: 'LUCIA GEITMANN',
        position: 'Vorstandsmitglied',
        imageSource: '/img/person/Lucia_Geitmann.jpg',
        bodyPrimary: 'Seit 2015 im Vorstand des Stadtjugendring Ulm e.V.',
        bodySecondary: 'Mitglied im Club Körperbehinderte und ihre Freunde',
        mailAddress: 'geitmann@sjr-ulm.de'
      }
    ],
    goals: [
      'Vertritt die Interessen und Bedarfe von Kindern und Jugendlichen in der Stadt',
      'Fördert und fordert das Engagement von jungen Menschen',
      'Bekennt sich zu Demokratie und Freiheit',
      'Wirkt mit an einer Welt, in der alle Menschen ohne Angst verschieden sein können'
    ],
    verifications: [
      'Anerkannter Träger der freien Jugendhilfe gem. § 75 SGB VIII Jugendhilfegesetz',
      'Anerkannter Träger der außerschulischen Jugendbildung gem. § 4 Jugendbildungsgesetz',
      'Anerkannter gemeinnütziger Verein im Sinne der §§ 51 ff. Abgabenordnung'
    ],
    committee: [
      { name: 'Daniel Best', organisation: 'UstA e.V.' },
      { name: 'Duran Enhas', organisation: 'Freundschaft, Kultur & Jugend e.V.' },
      { name: 'Julia Langendorf', organisation: 'BDKJ' },
      { name: 'Laura Weber', organisation: 'DGB Jugend' },
      { name: 'Marion Kalokerinos', organisation: 'andere baustelle e.V.' },
      { name: 'Matthias Baiker', organisation: 'ARGE JDAV' },
      { name: 'Maximilian Kapfer', organisation: 'Jugendfarm Ulm e.V.' },
      { name: 'Nadine Ambrosch', organisation: 'Serrando - Verein für Zirkuskünste Ulm e.V.' },
      { name: 'Stefan Lambacher', organisation: 'CVJM/Ev. Jugendwerk' },
      { name: 'Stefan Vogt', organisation: 'CVJM Ulm e.V. (Club Schilli)' }
    ]
  }),
  computed: {
    textJustify () {
      let value = 'justify-center'
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': value = 'justify-start'; break
        case 'sm': value = 'justify-start'; break
      }
      return value
    },
    textAlign () {
      let value = 'center'
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': value = 'left'; break
        case 'sm': value = 'left'; break
      }
      return value
    }
  }
}
</script>
